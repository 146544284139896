import './DashboardPage.css';
import React, { useState } from 'react';
import { Box, Button, Container, MenuItem, TextField, Typography } from '@mui/material';
import DynamicForm from '../../components/DynamicForm';
import { ApiProvider } from '../../providers/api';

function DashboardPage(props: any) {
    const apiProvider = new ApiProvider();
    const [form, setForm] = useState<{ firstname: string, lastname: string, email: string, campus: string, challenge: string, flags: string[] }>({
        firstname: '',
        lastname: '',
        email: '',
        campus: 'PRS',
        challenge: '',
        flags: [],
    })

    const [msg, setMsg] = useState({ text: '', color: '' })
    const [alreadySend,] = useState(localStorage.getItem('alreadySend') ?? '0')
    const [confirm, setConfirm] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const sendMessage = (msg: string, color: string) => { setConfirm(false); setMsg({ text: msg, color: color }); }

    const sendSuccess = (msg: string) => sendMessage(msg, '#b4f9b4')
    const sendInfo = (msg: string) => sendMessage(msg, '#ddf')
    const sendWarning = (msg: string) => sendMessage(msg, '#eea')
    const sendError   = (msg: string) => sendMessage(msg, '#fdd')

    const clearError = () => setMsg({ text: '', color: '#fff' })

    const nameregex = new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/),
          emailregex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/);

    async function submit(e: React.MouseEvent) {
        e.preventDefault();
        e.stopPropagation();

        // Le joueur ne peut pas jouer plus d'une fois.
        if (disabled) {
            sendWarning("Vous avez déjà joué.")
            return
        }

        form.firstname = form.firstname.trim().replace(/\s+/, " ");
        form.lastname = form.lastname.trim().replace(/\s+/, " ");
        form.email = form.email.trim().replace(/\s+/, " ");

        // Si le prénom ou nom ne sont pas valides.
        if (2 > form.firstname.length || 2 > form.lastname.length || !nameregex.test(form.firstname) || !nameregex.test(form.lastname)) {
            sendError("Veuillez revoir votre prénom/nom.")
            return
        }

        // Si l'email est invalide.
        if (!form.email || !emailregex.test(form.email)) {
            sendError("Veuillez revoir votre adresse mail.")
            return
        }

        // Si aucun challenge n'a été choisi.
        if (!form.challenge) {
            sendError("Veuillez choisir un challenge.")
            return
        }

        // Si aucun flag n'a été saisi.
        if (!form.flags.length) {
            sendError("Veuillez indiquez des flags.")
            return
        }

        // Si le formulaire est OK, alors l'utilisateur doit valider l'envoi puis
        // appuyer une seconde fois sur "ENVOYER".
        if (!confirm) {
            sendWarning(`<b>Attention</b>: tout envoi est définitif !<br/>Appuyer une seconde fois pour envoyer le formulaire.<br/><br/>Vous avez <b>${form.flags.length} flag${1 < form.flags.length ? 's' : ''}</b>`)
            setConfirm(true)
        } else {
            setDisabled(true)

            let error = await apiProvider.send(form)
            if (error === '') {
                sendInfo("Envoi en cours...")
                localStorage.setItem('alreadySend', '1');
                sendSuccess("Le formulaire à bien été reçu !")
            } else {
                setDisabled(false)
                sendError(error);
            }
        }
    }

    const challenges = [
        { value: "pentest", label: "Challenge Pentest" },
        { value: "coding", label: "Challenge Coding" },
    ];

    const campus = [
        { value: "PRS", label: "Paris" },
        { value: "MLV", label: "Marne la vallée" },
        { value: "SQY", label: "Saint-Quentin en Yvelines" },
        { value: "MTP", label: "Montpellier" },
        { value: "LYN", label: "Lyon" },
        { value: "NIE", label: "Nice" },
        { value: "LIL", label: "Lille" },
        { value: "DIS", label: "100% à distance" },
    ];

    return (
        <Container id="DashboardPage" sx={{
            paddingTop: 4,
            maxWidth: "100%",
            height: { xs: "calc(100vh - 140px)", lg: "calc(100vh - 144.5px)" },
            overflowY: "auto",
            backgroundColor: "#f6f6f6"
        }}>
            {/* Nous vérifions si l'utilisateur détient le token en localstorage
                lui empêchant de rejouer.
             */}
            {alreadySend === "1"
                // Si l'utilisateur a déjà joué, alors nous lui affichons ce message.
                ? <Typography
                    variant="h5"
                    textAlign='center'
                    sx={{
                        fontWeight: 500,
                        lineHeight: 2,
                        color: '#4f4f4f',
                        textDecoration: 'none',
                        marginBottom: { xs: 0, md: 2 },
                    }}>
                    Vous avez déjà envoyé le formulaire.
                </Typography>
                // Sinon nous présentons l'interface du flagger.
                : <Box
                    display='flex'
                    flexDirection='row'
                    sx={{ flexDirection: { xs: 'column', md: 'row' } }}
                >
                    {/* Zone : Informations personnelles + Formulaire. */}
                    <Box flex={3} sx={{ marginBottom: { xs: 4, md: 0 } }}>
                        {/* Titre. */}
                        <Typography
                            variant="h5"
                            noWrap
                            sx={{
                                fontWeight: 500,
                                lineHeight: 2,
                                color: '#4f4f4f',
                                textDecoration: 'none',
                                marginBottom: { xs: 0, md: 2 },
                            }}>
                            Informations personnelles
                        </Typography>
                        
                        {/* Paraphage de note. */}
                        <Typography
                            variant='body1'
                            sx={{
                                fontSize:'small',
                                color:'grey'
                            }}
                        >
                            Vos informations personnelles sont stockées dans un espace sécurisé et temporaire.
                            Elles seront automatiquement supprimées en fin de journée.
                        </Typography>

                        {/* Formulaire : input Prénom. */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstname"
                            label="Prénom"
                            name="firstname"
                            autoComplete="firstname"
                            autoFocus
                            sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                            InputProps={{ sx: { borderRadius: 2 } }}
                            onChange={e => {
                                let tmp = { ...form }
                                tmp.firstname = e.target.value
                                setForm(tmp)
                                clearError()
                            }}
                        />

                        {/* Formulaire : input Nom de famille. */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastname"
                            label="Nom de famille"
                            name="lastname"
                            autoComplete="lastname"
                            autoFocus
                            sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                            InputProps={{ sx: { borderRadius: 2 } }}
                            onChange={e => {
                                let tmp = { ...form }
                                tmp.lastname = e.target.value
                                setForm(tmp)
                                clearError()
                            }}
                        />

                        {/* Formulaire : input Adresse mail. */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            type='email'
                            autoFocus
                            sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                            InputProps={{ sx: { borderRadius: 2 } }}
                            onChange={e => {
                                let tmp = { ...form }
                                tmp.email = e.target.value
                                setForm(tmp)
                                clearError()
                            }}
                        />

                        {/* Formulaire : select Campus. */}
                        <TextField
                            margin="normal"
                            fullWidth
                            id="campus"
                            label="Campus"
                            name="campus"
                            autoComplete="campus"
                            autoFocus
                            sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                            InputProps={{ sx: { borderRadius: 2 } }}
                            select
                            defaultValue="PRS"
                            onChange={e => {
                                let tmp = { ...form }
                                tmp.campus = e.target.value
                                setForm(tmp)
                                clearError()
                            }}
                        >
                            {/* Génération de la liste des campus IPSSI. */}
                            {campus.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* Formulaire : select Challenge. */}
                        <TextField
                            margin="normal"
                            fullWidth
                            id="challenge"
                            label="Challenge"
                            name="challenge"
                            autoComplete="challenge"
                            autoFocus
                            sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                            InputProps={{ sx: { borderRadius: 2 } }}
                            select
                            defaultValue=""
                            onChange={e => {
                                let tmp = { ...form }
                                tmp.challenge = e.target.value
                                setForm(tmp)
                                clearError()
                            }}
                        >
                            {/* Génération de la liste de challenges assurés par IPSSI. */}
                            {challenges.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* Box du bouton d'envoi et du message d'état. */}
                        <Box sx={{
                            display: { xs: 'none', 'md': 'flex' },
                            flexDirection: 'column',
                        }}>

                            {/* Message d'état. */}
                            {msg.text &&
                                <Box sx={{
                                    marginTop: 2,
                                    backgroundColor: msg.color,
                                    padding: 2,
                                    borderRadius: 2,
                                    textAlign: 'center',
                                }}>
                                    <Typography
                                        dangerouslySetInnerHTML={{
                                            __html: msg.text
                                        }}
                                    />
                                </Box>
                            }

                            {/* Bouton d'envoi des flags. */}
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={disabled || alreadySend === "1"}
                                sx={{
                                    display: { xs: 'none', 'md': 'inline-flex' },
                                    marginTop: 2,
                                    marginBottom: 4,
                                    fontWeight: 600,
                                    lineHeight: 2,
                                    textDecoration: 'none',
                                    borderRadius: 2,
                                    backgroundColor: '#150ED1'
                                }}
                                onClick={submit}
                            >
                                Envoyer
                            </Button>
                        </Box>
                    </Box>

                    {/* Fomulaire de saisi des flags. */}
                    <Box flex={9} sx={{ marginLeft: { xs: 0, md: 4 } }}>
                        {/* Titre */}
                        <Typography
                            variant="h5"
                            noWrap
                            sx={{
                                fontWeight: 500,
                                lineHeight: 2,
                                color: '#4f4f4f',
                                textDecoration: 'none',
                                marginBottom: { xs: 0, md: 2 },
                            }}>
                            {`Flags - ${form.flags.length} trouvé` + (1 < form.flags.length ? 's' : '')}
                        </Typography>

                        {/* Form. */}
                        <DynamicForm
                            sx={{
                                height: { xs: '100%', md: "calc(100vh - 274px)",
                                lg: "calc(100vh - 278.5px)" },
                                overflowY: 'auto'
                            }}
                            form={form}
                            setForm={setForm}
                            />
                    </Box>

                    {/* <Box sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'column' }}>    
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={disabled}
                            sx={{

                                marginTop: 2,
                                fontWeight: 600,
                                lineHeight: 2,
                                textDecoration: 'none',
                                marginBottom: 2,
                            }}
                            onClick={submit}
                        >
                            Envoyer
                        </Button>

                        {msg.text !== '' &&
                            <Box sx={{
                                backgroundColor: msg.color,
                                padding: 2,
                                borderRadius: 2,
                                textAlign: 'center',
                            }}>
                                <Typography>
                                    {msg.text}
                                </Typography>
                            </Box>
                        }
                    </Box> */}
                </Box>
            }
        </Container >
    );
}

export default DashboardPage;
