import React from 'react';
import './RootPage.css';
import DashboardPage from '../DashboardPage/DashboardPage';
import { Routes, Route, Navigate } from 'react-router-dom';
import AppBar from '../../components/AppBar';
import { Box } from '@mui/material';

function RootPage() {

    return (
        <main>
            <Box sx={{ backgroundColor: "#f6f6f6" }}>
                <AppBar />
                <Routes>
                    <Route path="/" element={<DashboardPage />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Box>
        </main>
    );
}

export default RootPage;
