import axios from 'axios';
const mainApi = process.env.NODE_ENV === "production" ? 'https://flags.ipssi.cloud/api/' : 'http://localhost:8000/';

export const callApi = async (
	method: AxiosMethodsType,
	url: string,
	data: Object | null = null,
	headers: any,
	cb: any
) => {
	const options = {
		method: method,
		headers: headers,
		url: mainApi + url,
		data: data,
	};

	return await axios(options)
		.then(res => cb(res.data, null))
		.catch(err => cb(null, err));
};
