import { callApi } from '../utils/request';

export class ApiProvider {
    async send(form: any): Promise<string> {
        return await callApi('post', 'send', form, {}, (data: { alert: { variant: string; message: string; }; }, err: Error) => {
            if (err) {
                return "Erreur interne"
            } else if (data?.alert?.variant === 'success') {
                return ""
            } else {
                return data?.alert?.message
            }
        });
    }
}
