import React from 'react';
import { AppBar as NativeAppBar, Container, Toolbar, Box } from '@mui/material'
import logo from '../assets/logo.png';

function AppBar() {
    return (
        <NativeAppBar position="static" sx={{ backgroundColor: '#f6f6f6', boxShadow: 'none', height: 144, justifyContent: 'center' }}>
            <Container maxWidth="xl" >
                <Toolbar disableGutters>
                    <Box
                        component="img"
                        sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                        alt="Logo"
                        height={144}
                        src={logo}
                    />
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>
                        <Box
                            component="img"
                            alt="Logo"
                            height={144}
                            src={logo}
                        />
                    </Box>
                </Toolbar>
            </Container>
        </NativeAppBar>
    );
}

export default AppBar;
