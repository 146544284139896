import { Box, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Add } from '@mui/icons-material';

import { styled } from '@mui/material/styles';

const FlagTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset, &:hover fieldset": {
          border: "2px solid green"
        },
        "&.Mui-focused fieldset": {
          border: "2px solid orange"
        }
    }
});

function DynamicForm(props: any) {
    const form: any = props.form;
    const setForm: Function = props.setForm;

    const [newFlag, setNewFlag] = useState("");

    return <Box sx={props.sx ?? {}}>
        <Typography
            variant='body1'
            sx={{
                fontSize:'small',
                color:'grey'
            }}
        >
            <b>Note :</b> vous devez appuyer sur "Entrer" pour valider l'ajout d'un flag une fois saisi.
        </Typography>
        {form.flags.map((flag: string, idx: number) => {
            return <Box key={idx} display="flex" flexDirection="row" mb={3} mt={2}>
                <FlagTextField
                    required
                    fullWidth
                    sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                    InputProps={{ sx: { borderRadius: 2 } }}
                    inputProps={{
                        maxLength: 100,
                        minLength: 5
                    }}
                    value={flag}
                    onChange={e => {
                        let tmp = { ...form }
                        tmp.flags = [...form.flags]
                        tmp.flags[idx] = e.target.value
                        setForm(tmp)
                    }}
                />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 48,
                    height: 56,
                }}
                    onClick={(e) => {
                        let tmp = { ...form }
                        tmp.flags = [...form.flags]
                        tmp.flags.splice(idx, 1)
                        setForm(tmp)
                    }}
                >
                    <CloseIcon color='error' sx={{ fontSize: '28px', cursor:'pointer' }} />
                </Box>
            </Box>
        })}

        <Box display="flex" flexDirection="row" mb={3} mt={2}>
            <TextField
                required
                fullWidth
                variant="outlined"
                label='Ajouter un flag'
                autoFocus
                value={newFlag}
                sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                InputProps={{ sx: { borderRadius: 2 } }}
                inputProps={{
                    maxLength: 100,
                    minLength: 5
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter' && 4 < newFlag.length) {
                        let tmp = { ...form }
                        tmp.flags = [...form.flags, newFlag]
                        setForm(tmp)
                        setNewFlag('')
                    }
                }}
                onChange={e => {
                    setNewFlag(e.target.value)
                }}
            />
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 48,
                height: 56,
            }}
                onClick={(e) => {
                    let tmp = { ...form }
                    tmp.flags = [...form.flags, newFlag]
                    setForm(tmp)
                    setNewFlag('')
                }}
            >
                <Add color='primary' sx={{ fontSize: '28px', cursor:'pointer' }} />
            </Box>
        </Box>
    </Box>
}


export default DynamicForm;